import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

import HeaderHamburgerModal from "../includes/loader/modals/HeaderHamburgerModal";
export default function Header() {
    const [active, setActive] = useState("");
    const [isModal, setModal] = useState(false);

    const scroll = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <HeaderHamburgerModal
                isMenu={isModal}
                setMenu={setModal}
                active={active}
                setActive={setActive}
            />
            <Container id="navbar">
                <div className="wrapper">
                    <Logo>
                        <Link onClick={scroll} to="/">
                            <img
                                src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/Logo.svg"
                                alt="logo"
                            />
                        </Link>
                    </Logo>
                    <Nav>
                        <NavItem activeClass="active" to="home" spy={true} onClick={scroll}>
                            Home
                        </NavItem>
                        <NavItem activeclassname="active" to="about" spy={true} offset={-50}>
                            About Us
                        </NavItem>

                        <NavItem activeclassname="active" to="service" spy={true} offset={-35}>
                            Services
                        </NavItem>
                        <NavItem activeclassname="active" to="ourprojects" spy={true} offset={-35}>
                            Our Projects
                        </NavItem>
                        <NavItem activeclassname="active" to="sister" spy={true} offset={-35}>
                            Sister Companies
                        </NavItem>
                        <NavItem activeclassname="active" to="careers" spy={true} offset={-30}>
                            Careers
                        </NavItem>
                    </Nav>
                    <Hamburger onClick={() => setModal(!isModal)}>
                        <LineSpan isModal={isModal}></LineSpan>
                        <LineSpan type="middle" isModal={isModal}></LineSpan>
                        <LineSpan type="last" isModal={isModal}></LineSpan>
                    </Hamburger>
                </div>
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    padding: 15px;
    background: #fff;
    & .wrapper {
        width: 75%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1200px) {
            width: 80%;
        }
        @media (max-width: 980px) {
            width: 90%;
        }
        @media (max-width: 480px) {
            width: 95%;
        }
    }
`;
const Logo = styled.h1`
    width: 100px;

    @media all and (max-width: 768px) {
        width: 80px;
    }
    @media all and (max-width: 360px) {
        width: 70px;
    }
    img {
        display: block;
        width: 100%;
    }
`;
const Nav = styled.nav`
    display: flex;
    @media all and (max-width: 980px) {
        display: none;
    }
`;
const NavItem = styled(Link)`
    font-size: 16px;
    color: #232323;
    margin-right: 40px;
    cursor: pointer;
    transition: all 0.7s ease;

    &:last-child {
        margin-right: 0;
    }

    &.active {
        color: #2e8fce !important;
        transition: all 0.2s ease;
        font-family: "gordita_medium";
    }

    /* &:hover {
    color: #2e8fce;
  } */

    @media all and (max-width: 1320px) {
        margin-right: 25px;
    }
    @media all and (max-width: 1080px) {
        margin-right: 15px;
    }
`;
const Hamburger = styled.div`
    width: 50px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    cursor: pointer;
    display: none;
    @media all and (max-width: 980px) {
        display: flex;
    }
`;
const LineSpan = styled.span`
    width: ${({ type, isModal }) => (!isModal ? (type === "middle" ? "45px" : "45px") : "40px")};
    height: 5px;
    background: ${({ type }) => (type === "last" ? "#18484C" : "#18484C")};
    margin-bottom: 8px;
    border-radius: 30px;
    &:first-child {
        transform: ${({ isModal }) =>
            isModal ? "translateY(13px) rotate(45deg)" : "rotate(0deg)"};
        transition: all 0.5s ease;
    }
    &:nth-child(2) {
        transform: ${({ isModal }) => (isModal ? "translateX(-12px)" : "translateX(0px)")};
        opacity: ${({ isModal }) => (isModal ? "0" : "1")};
        transition: all 0.5s ease;
    }
    &:nth-child(3) {
        transform: ${({ isModal }) =>
            isModal ? "translateY(-13px) rotate(-45deg)" : "rotate(0deg)"};
        transition: all 0.5s ease;
    }
    transition: all 0.5s ease;
    @media all and (max-width: 960px) {
        width: ${({ type, isModal }) =>
            !isModal ? (type === "middle" ? "38px" : "38px") : "33px"};
        margin-bottom: 5px;
        &:first-child {
            transform: ${({ isModal }) =>
                isModal ? "translateY(10px) rotate(45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
        &:nth-child(2) {
            transition: all 0.5s ease;
        }
        &:nth-child(3) {
            transform: ${({ isModal }) =>
                isModal ? "translateY(-10px) rotate(-45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
    }
    @media all and (max-width: 540px) {
        width: ${({ type, isModal }) =>
            !isModal ? (type === "middle" ? "30px" : "30px") : "28px"};
        margin-bottom: 5px;
        &:first-child {
            transform: ${({ isModal }) =>
                isModal ? "translateY(10px) rotate(45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
        &:nth-child(2) {
            transition: all 0.5s ease;
        }
        &:nth-child(3) {
            transform: ${({ isModal }) =>
                isModal ? "translateY(-10px) rotate(-45deg)" : "rotate(0deg)"};
            transition: all 0.5s ease;
        }
    }
`;
