import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import SpotlightVideoModal from "../includes/loader/modals/SpotlightVideoModal";
import VideoModal from "../includes/loader/modals/VideoModal";

import spotBg from "../../assets/images/spot-bg.png";

function Spotlight() {
    const [isModal, setModal] = useState(false);

    return (
        <Container id="home">
            <BGContainer>
                {/* <SpotlightVideoModal
          isModal={isModal}
          setModal={setModal}
          thumbnail={
            'https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-11-2022/steyp-ad-thumbnail-for-talrop-website.jpg'
          }
          videoUrl={
            'https://player.vimeo.com/external/764557363.m3u8?s=009ead661977487ee9eeb060aeeaeecd908dbf78'
          }
        /> */}
                <VideoModal isModal={isModal} setModal={setModal} />

                <ImageContainer>
                    <img src={spotBg} alt="spotlight" />
                </ImageContainer>
            </BGContainer>
            <Cover className="wrapper">
                <LeftContainer>
                    <Heading>
                        Pioneering Digital <br></br> Construction
                    </Heading>
                    <Description>
                        We deliver commercial Design & Build construction projects as well govt
                        infrastructure projects within the scope, budget and time. We also develop
                        real-estate properties with premium apartments and villas.{" "}
                    </Description>
                    {/* <ButtonContainer>
            <Button to="contact" spy={true}>
              Contact Us
            </Button>
          </ButtonContainer> */}
                    <ButtonContainer>
                        <Button onClick={() => setModal(true)}>Play Video</Button>
                        <Icon src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/images/play.svg" />
                    </ButtonContainer>
                </LeftContainer>
                <RightContainer>
                    <BgImageContainer>
                        <BGImg src={spotBg} alt="image" />
                    </BgImageContainer>
                </RightContainer>
            </Cover>
        </Container>
    );
}

export default Spotlight;
const Container = styled.div`
    background: #2e8fce;
    padding: 280px 0px 250px 0px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url(${"https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex/bg-tower.svg"});
    background-position: bottom;
    width: 100%;
    overflow: hidden;
    @media (max-width: 1400px) {
        padding: 250px 0px 100px 0px;
    }
    @media (max-width: 1300px) {
        padding: 200px 0px 100px 0px;
    }
    @media (max-width: 1200px) {
        bottom: -38px;
    }
    @media (max-width: 1080px) {
        bottom: -38px;
    }
    @media (max-width: 980px) {
        padding: 175px 0px 70px 0px;
    }
    @media (max-width: 640px) {
        padding: 175px 0px 190px 0px;
    }
    @media (max-width: 408px) {
        padding: 175px 0px 150px 0px;
    }

    @media (max-width: 360px) {
        padding: 175px 0px 120px 0px;
    }
`;

const Cover = styled.div`
    justify-content: space-between;
    align-items: center;

    position: relative;
    @media all and (max-width: 980) {
    }

    &.wrapper {
        width: 75%;
        margin: 0 auto;
        @media (max-width: 1200px) {
            width: 80%;
            margin: 0 auto;
        }
        @media (max-width: 640px) {
            width: 85%;
            margin: 0 auto;
        }
        @media (max-width: 480px) {
            width: 90%;
            margin: 0 auto;
        }
    }
`;
const LeftContainer = styled.div``;
const Heading = styled.h1`
    color: #fff;
    font-size: 38px;
    margin-bottom: 30px;
    font-family: "gordita_bold";

    &.responsive {
        display: none;
        @media (max-width: 980px) {
            display: block;
            text-align: center;
        }
    }
    @media all and (max-width: 1400px) {
        font-size: 36px;
    }
    @media all and (max-width: 1300px) {
        font-size: 32px;
    }
    @media all and (max-width: 1200px) {
        font-size: 30px;
    }
    @media all and (max-width: 1080px) {
        font-size: 26px;
    }
    @media all and (max-width: 980px) {
        text-align: center;
        & br {
            display: none;
        }
    }
`;
const Description = styled.p`
    color: #fff;
    width: 35%;
    margin-bottom: 40px;
    @media all and (max-width: 1400px) {
        width: 41%;
    }
    @media all and (max-width: 1200px) {
        font-size: 16px;
        width: 40%;
    }
    @media all and (max-width: 1080px) {
        font-size: 15px;
        width: 40%;
    }
    @media all and (max-width: 980px) {
        font-size: 15px;
        width: 80%;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
        width: 100%;
    }
`;
// const ButtonContainer = styled.div`
//   width: 50%;
//   display: inline-block;

//   @media all and (max-width: 980px) {
//     width: 100%;
//     text-align: center;
//   }
// `
// const Button = styled(Link)`
//   background: #fff;
//   width: 115px;
//   text-align: center;
//   color: #2e8fce;
//   padding: 10px;
//   font-size: 14px;
//   border-radius: 7px;
//   font-family: 'gordita_medium';
//   cursor: pointer;
//   display: inline-block;
//   transition: all 0.7s ease;
//   &:hover {
//     background: linear-gradient(90deg, #20a0f3 -12.92%, #006baf 131.76%);
//     color: #fff;
//     transition: all 0.7s ease;
//   }
//   @media all and (max-width: 1400px) {
//     /* width: 30%; */
//     padding: 10px;
//   }

//   @media all and (max-width: 480px) {
//     width: 125px;
//   }
// `
const RightContainer = styled.div`
    /* position: relative; */
`;
const BgImageContainer = styled.div`
    width: 80%;
    position: absolute;
    /* left: 38%;
    bottom: -41px; */
    left: 36.5%;
    /* bottom: 30px; */

    top: 0;
    @media all and (max-width: 1400px) {
        /* bottom: -17px; */
        width: 75%;
        left: 43%;
    }
    @media all and (max-width: 1200px) {
        /* bottom: 40px; */
        width: 70%;
        left: 44%;
    }
    @media (max-width: 1080px) {
        bottom: 45px;
    }
    @media (max-width: 980px) {
        display: none;
    }
`;
const BGImg = styled.img`
    width: 100%;
    display: block;
`;
const Icon = styled.img``;
const BGContainer = styled.div`
    width: 100%;
    display: flex;
    /* justify-content: flex-end; */

    justify-content: center;
`;
const ImageContainer = styled.div`
    display: none;
    width: 80%;
    img {
        display: block;
        width: 100%;
    }
    @media (max-width: 980px) {
        width: 90%;
        margin-bottom: 50px;
        display: flex;
        /* transform: translateX(8px); */
    }
    @media (max-width: 768px) {
        width: 90%;
        margin-bottom: 50px;
        display: flex;
        /* transform: translateX(8px); */
    }
    @media (max-width: 480px) {
        /* transform: translateX(4px); */
        width: 90%;
    }
`;
const ButtonContainer = styled.div`
    width: 150px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: linear-gradient(90deg, #20a0f3 -12.92%, #006baf 131.76%);
    padding: 10px 15px;
    border-radius: 7px;
    @media all and (max-width: 980px) {
        margin: 0 auto;
        text-align: center;
    }
`;
const Button = styled.button`
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-family: "gordita_medium";
    cursor: pointer;
    padding: 10px;

    @media all and (max-width: 480px) {
        width: 125px;
        padding: 0px;
    }
`;
