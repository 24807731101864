import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import $ from 'jquery'
import VideoPlayer from '../../../video-player/src/VideoPlayer'
import CloseIcon from '../../../../assets/images/close.svg'

function SpotlightVideoModal({ isModal, setModal, videoUrl, thumbnail }) {
  const [isFullscreenIOS, setFullscreenIOS] = useState(false)

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    source: [
      {
        src:
          'https://storage.googleapis.com/coverr-main/mp4%2Fcoverr-an-early-morning-1579770136327.mp4',
        type: 'video/mp4',
      },
    ],
  }
  return (
    isModal && (
      <BackContainer
        screen_mode={isFullscreenIOS ? 'fullscreen' : ''}
        id="invite-video"
      >
        <Overlay
          screen_mode={isFullscreenIOS ? 'fullscreen' : ''}
          onClick={() => {
            setModal(false)
            setFullscreenIOS(false)
          }}
        ></Overlay>

        <VideoModalContainer screen_mode={isFullscreenIOS ? 'fullscreen' : ''}>
          {/* <Video className="player-wrapper">
                        <ReactPlayer
                            className="react-player"
                            url={activeTest}
                            playing={true}
                            controls={true}
                            width="100%"
                            height="100%"
                        />
                    </Video>
                    <CloseIconContainer onClick={() => setModal(false)}>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/close.svg"
                            alt="Logo"
                        />
                    </CloseIconContainer> */}
          <Close
            onClick={() => {
              setModal(false)
              setFullscreenIOS(false)
            }}
          >
            <img src={CloseIcon} alt="close icon" />
          </Close>
          <VideoPlayer
            source={videoUrl}
            autoplay={true}
            videoJsOptions={videoJsOptions}
            setFullscreenIOS={setFullscreenIOS}
            thumbnail={thumbnail}
          />
        </VideoModalContainer>
      </BackContainer>
    )
  )
}

export default SpotlightVideoModal

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0;
`
const Close = styled.div`
  position: absolute;
  right: -40px;
  // left:-20px;
  width: 30px;
  cursor: pointer;
  // height:50px;
  img {
    width: 100%;
    display: block;
  }
`
const Overlay = styled.div`
  background-color: ${(props) =>
    props.screen_mode === 'fullscreen' ? 'rgb(0, 0, 0)' : 'rgba(0, 0, 0, 0.5)'};
  width: 100%;
  height: 100vh;
`
const VideoModalContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9px;
  background-color: #fff;
  width: ${(props) => (props.screen_mode === 'fullscreen' ? '100%' : '70%')};
  @media (max-width: 480px) {
    width: 97%;
  }
`
const Video = styled.div`
  position: relative;
  /* width: 750px;
    height: 425px; */
`
// const CloseIcon = styled.img`
//     display: block;
//     width: 100%;
// `;
const CloseIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: -35px;
  width: 25px;
  cursor: pointer;
  @media (max-width: 640px) {
    right: -30px;
    width: 22px;
  }
  @media (max-width: 480px) {
    right: 14px;
    top: -33px;
    width: 11px;
  }
`
const VideoDetail = styled.div`
  margin-top: 5px;
  padding: 15px 15px 25px 15px;
  justify-content: space-between;
  @media (max-width: 480px) {
    padding: 7px 14px;
  }
`
const VideoTitle = styled.h2`
  font-family: 'baloo_paaji_2medium';
  font-size: 14px;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`
const VideoNumbers = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`
const VideoLikes = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`
const LikeImg = styled.img`
  display: block;
  width: 20px;
  transform: translate(0px, -1px);
  @media (max-width: 480px) {
    width: 15px;
  }
  @media (max-width: 360px) {
    width: 10px;
    transform: translate(0px, 0px);
  }
`
const LikeNum = styled.span`
  margin-left: 10px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
  @media (max-width: 360px) {
    font-size: 13px;
  }
`
const VideoViews = styled.div`
  display: flex;
  align-items: center;
`
const ViewImg = styled.img`
  display: block;
  width: 25px;
  transform: translate(0px, -1px);
  @media (max-width: 480px) {
    width: 20px;
  }
  @media (max-width: 360px) {
    width: 15px;
    transform: translate(0px, 0px);
  }
`
const ViewNum = styled.span`
  margin-left: 10px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
  @media (max-width: 360px) {
    font-size: 13px;
  }
`
