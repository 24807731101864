import React from 'react'
import { Routes, Route } from 'react-router-dom'
import IfuexLandingPage from '../../screens/IfuexLandingPage'

function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<IfuexLandingPage />} />
    </Routes>
  )
}

export default AppRouter
