import React, { useEffect, useState } from "react";
import styled from "styled-components";
import About from "./About";
import Footer from "./Footer";
import Header from "./Header";
import Service from "./Service";
import Spotlight from "./Spotlight";
import WorkWithUs from "./WorkWithUs";
import CallImage from "../../assets/images/whatsapp.svg";
import CallingImage from "../../assets/images/calling.svg";
import WhatImage from "../../assets/images/whatsapp.png";
import CloseImage from "../../assets/images/closegreen.svg";
import Careers from "./Careers";
import SisterConcern from "./SisterConcern";
import Missions from "./Missions";
import OurProjects from "./OurProjects";
import ContactForm from "./ContactForm";

function IfuexLandingPage() {
    const [isBubble, setBubble] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Header />
            <Spotlight />
            <About />
            <Missions />
            <Service />
            <OurProjects />
            <WorkWithUs />
            <SisterConcern />
            <Careers />

            <ContactForm />
            <Footer />
            <Bubble>
                {isBubble ? null : (
                    <CallBubble
                        isBubble={isBubble}
                        onClick={() => {
                            setBubble(true);
                        }}
                    >
                        <img src={CallImage} alt="icon" />
                    </CallBubble>
                )}
                <WhatsBubble
                    isBubble={isBubble}
                    href="tel:+91 9746373223"
                    className={isBubble && "call-active"}
                >
                    <img src={CallingImage} alt="icon" />
                </WhatsBubble>
                <WhatsBubble
                    isBubble={isBubble}
                    className={isBubble && "active"}
                    href="https://wa.me/+91 9746373223"
                    target="_blank"
                >
                    <img src={WhatImage} alt="icon" />
                </WhatsBubble>
                <CloseBubble isBubble={isBubble} onClick={() => setBubble(false)}>
                    <img src={CloseImage} alt="icon" />
                </CloseBubble>
            </Bubble>
        </div>
    );
}

export default IfuexLandingPage;

const Bubble = styled.div`
    width: 150px;
    height: 50px;
    /* border-radius:50%;
  background: linear-gradient(180deg, #0FA76F 0%, #0B6846 100%); */
    position: fixed;
    bottom: 10%;
    right: 10%;
    z-index: 11;
`;
const CallBubble = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #05cc47;
    padding: 15px;
    position: absolute;
    right: -50px;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 10;
    transition: all 0.5s ease;
    top: 35px;
    &.active {
        right: 80%;
        transition: all 0.5s ease;
    }
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 640px) {
        right: -15px;
        top: 15px;
    }
`;
const WhatsBubble = styled.a`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(180deg, #0fa76f 0%, #0b6846 100%);
    padding: 15px;
    position: absolute;
    right: -50px;
    top: 35px;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    z-index: 8;
    transition: all 0.5s ease;
    &.active {
        right: 20%;
        transition: all 0.5s ease;
        @media all and (max-width: 640px) {
            right: 40%;
        }
    }
    &.call-active {
        right: 70%;
        transition: all 0.5s ease;
        @media all and (max-width: 640px) {
            right: 90%;
        }
    }
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 640px) {
        right: -15px;
        top: 15px;
    }
`;
const CloseBubble = styled.div`
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50%;
    padding: 20px;
    position: absolute;
    right: -50px;
    top: 35px;
    animation: ${({ isBubble }) => (isBubble ? "" : "jumb")};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
    border: 1px solid #0b6846;
    z-index: 8;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 640px) {
        right: -15px;
        top: 15px;
    }
`;
