import React from "react";

//packages
import styled from "styled-components";

const Footer = () => {
    return (
        <>
            <FooterContainer id="contact">
                <Container className="wrapper">
                    <LeftSection>
                        <Logo>
                            <a href="/">
                                <img
                                    src={require("../../assets/images/ifuex-logo.svg")}
                                    alt="ifuex-Logo"
                                />
                            </a>
                        </Logo>
                        <Description>
                            iFuex is a full-service Contractor for Civil, Mechanical and Electrical
                            construction projects.
                        </Description>
                    </LeftSection>
                    <MiddleSection>
                        <LogoBox>
                            <Title>Managed by</Title>
                            <LogoImage target="_blank" href="https://www.stikcon.com/">
                                <img
                                    src={require("../../assets/images/stikcon-logo.svg")}
                                    alt="stikcon-logo"
                                />
                            </LogoImage>
                        </LogoBox>
                        <LogoBox>
                            <Title>An initiative from</Title>
                            <LogoImage target="_blank" href="https://talrop.com/">
                                <img
                                    src={require("../../assets/images/talrop-logo.svg")}
                                    alt="talrop-logo"
                                />
                            </LogoImage>
                        </LogoBox>
                    </MiddleSection>
                    <RightSection>
                        <Title className="contact">Contact</Title>
                        <ContactSection>
                            <Button href="tel:+91 974 637 3223">+91 974 637 3223</Button>
                            <Button href="tel:+91 790 279 9207">+91 790 279 9207</Button>

                            <Button href="mailto:projects@ifuex.in">projects@ifuex.in</Button>
                            <Button href="mailto:business@ifuex.in">business@ifuex.in</Button>
                        </ContactSection>
                    </RightSection>
                </Container>
                <Line className="wrapper"></Line>
                <CopyrightSection className="wrapper">
                    <LeftBox>
                        <p>@2023 Ifuex Infrastructures and Builders LLP</p>
                    </LeftBox>
                    {/* FOR FUTURE */}
                    {/* <RightBox>
                        <SocialMediaLogo>
                            <img src={require("../../assets/images/Facebook.svg")} alt="fb" />
                        </SocialMediaLogo>
                        <SocialMediaLogo>
                            <img src={require("../../assets/images/Twitter.svg")} alt="twitter" />
                        </SocialMediaLogo>
                        <SocialMediaLogo>
                            <img src={require("../../assets/images/LinkedIn.svg")} alt="linkedin" />
                        </SocialMediaLogo>
                    </RightBox> */}
                </CopyrightSection>
            </FooterContainer>
        </>
    );
};

export default Footer;

const FooterContainer = styled.footer`
    background-color: #fff;
    padding: 100px 0 0 0;
    @media all and (max-width: 1280px) {
        padding: 80px 0 0 0;
    }
    @media all and (max-width: 980px) {
        padding: 70px 0 0 0;
    }
    @media all and (max-width: 640px) {
        padding: 60px 0 0 0;
    }
    @media all and (max-width: 480px) {
        padding: 50px 0 0 0;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    padding-bottom: 30px;
    &.wrapper {
        width: 75%;
        margin: 0 auto;
        @media (max-width: 640px) {
            width: 85%;
            margin: 0 auto;
        }
        @media (max-width: 480px) {
            width: 90%;
            margin: 0 auto;
        }
    }
    @media all and (max-width: 1200px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

//LEFT BOX STYLES
const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media all and (max-width: 1200px) {
        align-items: center;
        margin-bottom: 20px;
        width: 100%;
    }
    @media all and (max-width: 980px) {
        border-right: none;
        margin-bottom: 30px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;

const Logo = styled.h1`
    width: 85px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-bottom: 25px;
    &.talrop {
        margin: 20px auto;
        width: 80px;
        @media all and (max-width: 640px) {
            width: 60px;
        }
    }
    a {
        display: block;
    }
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 980px) {
        margin: 0 auto 30px auto;
    }
    @media all and (max-width: 640px) {
        width: 70px;
        &.active {
            width: 45px;
        }
    }
    @media all and (max-width: 480px) {
        width: 60px;
        &.active {
            width: 45px;
        }
    }
`;

const Description = styled.p`
    color: #747474;
    font-size: 13px;
    max-width: 370px;
    @media all and (max-width: 1200px) {
        max-width: unset;
        text-align: center;
    }
    @media all and (max-width: 980px) {
        width: 70%;
    }
    @media all and (max-width: 768px) {
        width: 85%;
    }
    @media all and (max-width: 420px) {
        width: 100%;
    }
`;

//MIDDLE BOX STYLES
const MiddleSection = styled.div`
    display: flex;
    align-items: center;
`;
const LogoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    &:first-child {
        margin-right: 50px;
    }
`;
const Title = styled.h4`
    margin-bottom: 25px;
    font-size: 18px;
    color: #0a0a0a;
    &.contact {
        font-family: "gordita_medium";
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const LogoImage = styled.a`
    width: 42px;
    cursor: pointer;
    img {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        width: 36px;
    }
`;

//RIGHT BOX STYLES
const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media all and (max-width: 1200px) {
        margin-left: 50px;
        align-items: center;
    }
    @media all and (max-width: 768px) {
        margin-left: 0;
        width: 100%;
        margin-top: 30px;
    }
`;
const ContactSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media all and (max-width: 1200px) {
        align-items: center;
    }
    @media all and (max-width: 768px) {
        flex-direction: row;
        width: 70%;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center;
    }
    @media all and (max-width: 450px) {
        width: 90%;
    }
`;
const Button = styled.a`
    color: #747474;
    font-size: 14px;
    cursor: pointer;
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;

const Line = styled.div`
    border-bottom: 1px solid #1a84c9;
`;

//COPYRIGHT SECTION[BOTTOM OF FOOTER]
const CopyrightSection = styled.div`
    display: flex;
    //FUTURE STYLE
    /* justify-content: space-between; */

    justify-content: center;
    align-items: center;
    padding: 30px 0;
    @media all and (max-width: 540px) {
        flex-direction: column-reverse;
    }
`;
const LeftBox = styled.div`
    p {
        font-size: 14px;
    }
    @media all and (max-width: 540px) {
        margin-top: 15px;
        p {
            text-align: center;
            @media all and (max-width: 350px) {
                font-size: 12px;
            }
        }
    }
`;

//SOCIAL MEDIA ICONS BOX
//FOR FUTURE

// const RightBox = styled.div`
//     display: flex;
//     align-items: center;
// `;
// const SocialMediaLogo = styled.div`
//     cursor: pointer;
//     margin-right: 25px;
//     width: 36px;
//     height: 36px;
//     img {
//         width: 100%;
//         height: 100%;
//     }
//     &:last-child {
//         margin-right: 0;
//     }
//     @media all and (max-width: 640px) {
//         width: 30px;
//         height: 30px;
//     }
// `;
