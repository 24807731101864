import React, { useState } from "react";

//packages
import styled from "styled-components";
import ReactPlayer from "react-player";

//images
import thumbnail from "../../assets/images/video-thumbnail.png";
function ContactForm() {
    const mailId = "business@ifuex.in";
    const [formData, setFormData] = useState({
        emailBody: "",
        userMail: "",
    });

    const [errors, setErrors] = useState({
        emailBody: "",
        userMail: "",
    });

    const handleDataChange = (e) => {
        const value = e.target.value.trimStart();
        setFormData((prev) => ({ ...prev, [e.target.name]: value }));
        setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
    };

    const validateForm = () => {
        const fields = Object.keys(formData);
        const errorObjCopy = { ...errors };

        fields.map((field) => {
            if (formData[field] === "") {
                errorObjCopy[field] = "This field is required";
            } else {
                errorObjCopy[field] = "";
            }

            if (formData[field]) {
                if (field === "userMail") {
                    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData[field])) {
                        errorObjCopy[field] = "Invalid email";
                    } else {
                        errorObjCopy[field] = "";
                    }
                }
            }
        });

        setErrors(errorObjCopy);
        if (Object.values(errorObjCopy).some((error) => error !== "")) {
            return false;
        }
        return true;
    };

    const videoURL =
        "https://player.vimeo.com/external/829293188.m3u8?s=d3dfbffeef60e0bde1a566954d0804e351ad42d1";

    //sending mail function
    const handleMailSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const body = formData.emailBody;
            const from = "hasanul@talrop.com";
            const to = mailId;
            const mailtoLink = `mailto:${to}?from=${encodeURIComponent(
                from
            )}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
            return;
        }
    };

    return (
        <MainContainer>
            <Wrapper>
                <LeftBox>
                    <TopBox>
                        <SubHeading>Get a Quote</SubHeading>
                        <MainHeading>
                            Let’s <span>Talk</span>
                        </MainHeading>
                        <Description>
                            We are here to get in touch with you,
                            <br /> Knock us anytime.
                        </Description>
                    </TopBox>
                    <Form onSubmit={handleMailSubmit}>
                        <InputContainer>
                            <Input
                                type="text"
                                placeholder="Your Email"
                                onChange={handleDataChange}
                                name="userMail"
                                value={formData.userMail}
                            />
                            <p className="error">{errors.userMail}</p>
                        </InputContainer>

                        <InputContainer>
                            <Input
                                type="text"
                                placeholder="Message"
                                name="emailBody"
                                onChange={handleDataChange}
                                value={formData.emailBody}
                            />
                            <p className="error">{errors.emailBody}</p>
                        </InputContainer>

                        <Button type="submit">Submit</Button>
                    </Form>
                </LeftBox>
                <Rightbox>
                    <VideoContainer>
                        <ReactPlayer
                            url={videoURL}
                            playing={true}
                            thumbnail={thumbnail}
                            controls={true}
                            width="100%"
                            height="100%"
                            muted
                            autoPlay
                            loop={true}
                        ></ReactPlayer>
                    </VideoContainer>
                </Rightbox>
            </Wrapper>
        </MainContainer>
    );
}

export default ContactForm;

const MainContainer = styled.section`
    padding-top: 100px;
    @media all and (max-width: 1280px) {
        padding-top: 80px;
    }
    @media all and (max-width: 980px) {
        padding-top: 70px;
    }
    @media all and (max-width: 640px) {
        padding-top: 60px;
    }
    @media all and (max-width: 480px) {
        padding-top: 50px;
    }
`;
const Wrapper = styled.section`
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media all and (max-width: 1050px) {
        flex-direction: column;
        justify-content: space-between;
        position: unset;
    }
    @media all and (max-width: 680px) {
        width: 90%;
    }
`;
const LeftBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    background: linear-gradient(105.86deg, #0d0e1b 11.27%, #181d45 92.4%);
    border-radius: 16px 0px 0px 16px;
    padding: 50px;
    width: 45%;
    @media all and (max-width: 1050px) {
        border-radius: 16px;
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 30px;
    }
`;

const TopBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
const SubHeading = styled.h2`
    color: #ffffff;
    font-size: 18px;
    opacity: 0.7;
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const MainHeading = styled.h1`
    font-size: 40px;
    color: #fff;
    font-family: "gordita_bold";
    span {
        color: #2e8fce;
    }
    @media all and (max-width: 1280px) {
        font-size: 35px;
    }
    @media all and (max-width: 1080px) {
        font-size: 32px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
    }
    @media all and (max-width: 768px) {
        font-size: 28px;
    }
    @media all and (max-width: 568px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 26px;
    }
`;
const Description = styled.p`
    font-size: 14px;
    color: #fff;
    @media all and (max-width: 1050px) {
        br {
            display: none;
        }
    }
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 35px;
    p.error {
        position: absolute;

        font-size: 14px;
        color: red;
    }
`;

const InputContainer = styled.div`
    position: relative;
`;
const Input = styled.input`
    background-color: #31375b !important;
    height: 55px;
    width: 100%;
    padding: 0 15px;
    font-size: 16px;
    border-radius: 8px;
    color: #fff;
    ::placeholder {
        color: #ffffff;
        opacity: 0.5;
        font-size: 16px;
    }
    @media all and (max-width: 1280px) {
        height: 48px;
    }
    @media all and (max-width: 480px) {
        height: 45px;
    }
`;
const Button = styled.button`
    background: linear-gradient(90deg, #20a0f3 -12.92%, #006baf 131.76%);
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    height: 50px;
    cursor: pointer;
    @media all and (max-width: 1280px) {
        height: 48px;
    }
    @media all and (max-width: 480px) {
        height: 45px;
    }
`;

const Rightbox = styled.div`
    width: 55%;
    border-radius: 16px;
    position: absolute;
    right: 0;
    z-index: 10;
    height: 520px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    @media all and (max-width: 1050px) {
        flex-direction: column;
        position: unset;
        top: unset;
        transform: unset;
        width: 80%;
        height: auto;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;

const VideoContainer = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;

    img {
        height: 100%;
        width: 100%;
    }

    @media all and (max-width: 1280px) {
        img {
            object-fit: cover;
        }
    }
`;
