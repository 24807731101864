import React from "react";

//packages
import styled from "styled-components";

export default function OurProjects() {
    const techiesParks = [
        {
            id: 1,
            name: "Techies Park, Mannarkkad",
            image: require("../../assets/images/techies-park-1.png"),
            location: "Location:  MEA Engineering College, Perinthalmanna",
            structure: "Structure Type: Prefab(G+2)",
            area: "Area: 11470 sqft",
        },
        {
            id: 2,
            name: "Techies Park, Malappuram",
            image: require("../../assets/images/techies-park-2.png"),
            location: "Location: Darunnajath School, Wandoor",
            structure: "Structure Type: Prefab(G+Basement)",
            area: "Area: 5400 sqft",
        },
        {
            id: 3,
            name: "Techies Park, Kasaragod",
            image: require("../../assets/images/techies-park-3.png"),
            location: "Location: Greenwoods Public School, Bekal",
            structure: "Structure Type: Prefab",
            area: "Area: 4565 sqft",
        },
        {
            id: 4,
            name: "Techies Park, Kannur",
            image: require("../../assets/images/techies-park-4.png"),
            location: "Location: Ideal College, Kannur",
            structure: "Structure Type: Prefab",
            area: "Area: 5700 sqft",
        },
        {
            id: 5,
            name: "Techies Park, Malappuram",
            image: require("../../assets/images/techies-park-5.png"),
            location: "Location: MEA Engineering College, Perinthalmanna",
            structure: "Structure Type: Prefab(Basement+G)",
            area: "Area: 5683 sqft",
        },
    ];
    return (
        <MainContainer id="ourprojects">
            <Wrapper>
                <TopBox>
                    <SubHeading>Projects</SubHeading>
                    <MainHeading>Our Upcoming Projects</MainHeading>
                    <Description>
                        We have been awarded by Techies Park Infrastructures to Design & Build about
                        100 Techies Parks in the selected schools and colleges across Kerala. Just
                        enlisting below those slated for delivery in the year 2023-2024.
                    </Description>
                </TopBox>
                <BottomContainer>
                    {techiesParks.map((techiespark) => (
                        <TechiesParkCard>
                            <Title>{techiespark.name}</Title>
                            <ImageBox>
                                <img src={techiespark.image} alt="techies-park" />
                            </ImageBox>
                            <Location>{techiespark.location}</Location>
                            <Structure>{techiespark.structure}</Structure>
                            <Area>{techiespark.area}</Area>
                        </TechiesParkCard>
                    ))}
                </BottomContainer>
            </Wrapper>
        </MainContainer>
    );
}

const MainContainer = styled.section`
    padding: 100px 0;
    @media all and (max-width: 1280px) {
        padding: 80px 0;
    }
    @media all and (max-width: 980px) {
        padding: 70px 0;
    }
    @media all and (max-width: 640px) {
        padding: 60px 0;
    }
    @media all and (max-width: 480px) {
        padding: 50px 0;
    }
`;
const Wrapper = styled.section`
    width: 52%;
    margin: 0 auto;
    @media all and (max-width: 1450px) {
        width: 60%;
    }
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 1080px) {
        width: 80%;
    }
    @media all and (max-width: 980px) {
        width: 85%;
    }
`;
const TopBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    text-align: center;
`;
const SubHeading = styled.h3`
    background: linear-gradient(90deg, #20a0f3 -12.92%, #006baf 131.76%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: "gordita_medium";
    font-size: 17px;
`;
const MainHeading = styled.h1`
    font-family: "gordita_bold";
    color: #282c3f;
    font-size: 28px;
`;
const Description = styled.p`
    color: #747474;
    font-size: 16px;
    @media all and (max-width: 1080px) {
        font-size: 14px;
    }
    @media all and (max-width: 1080px) {
        width: 85%;
    }
    @media all and (max-width: 480px) {
        width: 95%;
    }
`;
const BottomContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: first baseline;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;
    @media all and (max-width: 480px) {
        margin-top: 35px;
    }
`;
const TechiesParkCard = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 4px 61px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    padding: 25px;
    @media all and (max-width: 1080px) {
        width: 45%;
    }
    @media all and (max-width: 768px) {
        width: 90%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Title = styled.h2`
    font-family: "gordita_medium";
    color: #282c3f;
    font-size: 16px;
`;

const ImageBox = styled.div`
    border-radius: 15px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
    }
`;
const Location = styled.p`
    color: #747474;
    font-family: "gordita_medium";
    font-size: 14px;
`;
const Structure = styled.span`
    color: #747474;
    font-size: 14px;
`;
const Area = styled.span`
    color: #747474;
    font-size: 14px;
`;
