import React, { useState } from 'react'
import styled from 'styled-components'
import pdf from '../../assets/MCC COMPANY PROFILE.pdf'

export default function SisterConcern() {
  const [isitem, Setitem] = useState(false)

  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = pdf
    link.download = 'MCC COMPANY PROFILE.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const ourProjects = [
    {
      id: 1,
      title: 'Corporate Office',
      description:
        '2nd Floor, Logtech Tower, Bypass Junction, Kazhakkoottam, Trivandrum, Kerala, India-695582',
      path: 'https://goo.gl/maps/W6vG3YyuFiea2mF96',
    },
    {
      id: 2,
      title: 'Registered Office',
      description:
        '8th Floor, Infra Futura Building, Thrikkakara, Ernakulam-682021',
      path: 'https://goo.gl/maps/r9nUpWsuoEb3SSR67',
    },
  ]

  return (
    <Container id="sister">
      <Cover className="wrapper">
        <p className="blue">Sister Companies</p>
        <Head>Our partners in progress</Head>
        <Description>
          To be a preferred contractor for the private and public sector, we
          have the leverage from our sister concerns in this space as well.
        </Description>

        <MiddleContainer>
          <LeftContainer>
            <Top>
              <TopImage
                src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fimage-1.png"
                alt="spot"
              />
            </Top>
            <Bottom>
              <BottoomImageDiv>
                <BottoomImage
                  src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fimage-2.png"
                  alt="spot"
                />
              </BottoomImageDiv>
              <BottoomImageDiv>
                <BottoomImage
                  src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fimage-3.png"
                  alt="spot"
                />
              </BottoomImageDiv>
            </Bottom>
          </LeftContainer>
          <RightContainer>
            <Card href="https://www.stikcon.com/" target="_blank">
              <Left>
                <LogoDiv>
                  <Logo
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fstickon.svg"
                    alt="Logo"
                  />
                </LogoDiv>
                <Name>Stikcon Tech Pte Ltd</Name>
                <Place>Singapore</Place>
                <Mail href="mailto:business@stikcon.com">
                  business@stikcon.com
                </Mail>
              </Left>
              <Right>
                <IconContainer>
                  <Icon
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fright-icon.svg"
                    alt="Icon"
                  />
                </IconContainer>
              </Right>
            </Card>

            <Card
              href="https://www.stikcon.com/"
              target="_blank"
              className="stickon"
            >
              <Left>
                <LogoDiv className="stickon">
                  <Logo
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fstickon-2.svg"
                    alt="Logo"
                  />
                </LogoDiv>
                <Name>Project Stikcon Pvt Ltd</Name>
                <Place>Ernakulam, Kerala </Place>
                <Mail href="mailto:business@stikcon.com">
                  business@stikcon.com
                </Mail>
              </Left>
              <Right>
                <IconContainer>
                  <Icon
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fright-icon.svg"
                    alt="Icon"
                  />
                </IconContainer>
              </Right>
            </Card>

            <Card onClick={handleDownload}>
              <Left>
                <LogoDiv className="malabar">
                  <Logo
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fmalabar.svg"
                    alt="Logo"
                  />
                </LogoDiv>
                <Name>Malabar Construction and Contractors</Name>
                <Place>Trivandrum, Kerala</Place>
                <Mail href="mailto:mcctvm.office@gmail.com">
                  mcctvm.office@gmail.com
                </Mail>
              </Left>
              <Right>
                <IconContainer>
                  <Icon
                    src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Fright-icon.svg"
                    alt="Icon"
                  />
                </IconContainer>
              </Right>
            </Card>
          </RightContainer>
        </MiddleContainer>

        <Ul>
          <Heading>Our Main Office</Heading>
          <Div>
            {ourProjects.map((item) => (
              <Li
                className={item.id === isitem ? 'active' : ''}
                onMouseEnter={() => {
                  Setitem(item.id)
                }}
                onMouseLeave={() => {
                  Setitem('')
                }}
                key={item.id}
              >
                <Details>
                  <Title className={item.id === isitem ? 'active' : ''}>
                    {item.title}
                  </Title>
                  <SubDescription
                    className={item.id === isitem ? 'active' : ''}
                  >
                    {item.description}
                  </SubDescription>
                </Details>
                <ButtonContainer>
                  <Button href={item.path} target="_blank">
                    Locate Map
                    <LocationIcon>
                      <Location
                        src="https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/ifuex%2Flocation.svg"
                        alt="Icon"
                      />
                    </LocationIcon>
                  </Button>
                </ButtonContainer>
              </Li>
            ))}
          </Div>
        </Ul>
      </Cover>
    </Container>
  )
}
const Container = styled.section`
  padding: 100px 0;
  background-color: #fbfbfb;
  background-repeat: no-repeat;
  background-size: cover;
  @media all and (max-width: 1280px) {
    padding: 80px 0;
  }
  @media all and (max-width: 980px) {
    padding: 70px 0;
  }
  @media all and (max-width: 640px) {
    padding: 60px 0;
  }
  @media all and (max-width: 480px) {
    padding: 50px 0;
  }
`
const Cover = styled.section`
  &.wrapper {
    width: 75%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 80%;
    }
    @media all and (max-width: 980px) {
      flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
      width: 85%;
    }
    @media (max-width: 480px) {
      width: 90%;
    }
  }
  p.blue {
    background-image: linear-gradient(45deg, #20a0f3, #030d13);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

    font-family: 'gordita_medium';
    margin-bottom: 15px;
  }
`
const Head = styled.h3`
  color: #212121;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'gordita_bold';

  @media all and (max-width: 1080px) {
    font-size: 24px;
  }
  @media all and (max-width: 980px) {
  }

  b {
    color: #0bbe61;
    font-family: gordita_bold;
  }
`
const Description = styled.p`
  font-size: 16px;
  width: 70%;
  margin-bottom: 50px;
  color: #747474;
  @media all and (max-width: 1080px) {
    font-size: 14px;
    width: 100%;
  }
`

const Ul = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  justify-content: space-between;
  align-items: baseline;
  border: 1.5px solid #20a0f3;
  border-radius: 12px;
  @media all and (max-width: 1200px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
  @media all and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`
const Li = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s ease;
  text-align: center;
  box-shadow: 0 9px 61px #0000000a;

  &:hover {
    box-shadow: 0px 4px 40px rgb(0 0 0 / 16%);
  }
  &:nth-last-child(-n + 3) {
    margin-bottom: 0;
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }
  @media all and (max-width: 640px) {
  }
  @media all and (max-width: 480px) {
  }
  @media all and (max-width: 360px) {
  }
`

const Details = styled.div`
  font-size: 23px;
  min-height: 140px;
  @media all and (max-width: 980px) {
    min-height: 190px;
  }
  @media all and (max-width: 768px) {
    min-height: auto;
  }
`
const Title = styled.h5`
  color: #0a0a0a;
  margin-bottom: 30px;
  font-size: 16px;
  font-family: 'gordita_medium';
  text-align: left;
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
`
const SubDescription = styled.p`
  color: #747474;
  font-size: 14px;
  text-align: left;
  margin-bottom: 30px;
  @media all and (max-width: 980px) {
    font-size: 14px;
  }
`

const ButtonContainer = styled.div`
  width: 50%;
  text-align: left;
`
const Button = styled.a`
  width: 150px;
  text-align: center;
  padding: 10px;
  font-size: 14px;
  border-radius: 7px;
  font-family: 'gordita_medium';
  cursor: pointer;
  display: flex;
  background: linear-gradient(90deg, #20a0f3 -12.92%, #006baf 131.76%);
  color: #fff;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.7s ease;

  &:hover {
    transition: all 0.7s ease;
    background: linear-gradient(90deg, #006baf -12.92%, #20a0f3 131.76%);
  }
  @media all and (max-width: 1400px) {
    padding: 10px;
  }

  @media all and (max-width: 980px) {
    width: 140px;
    font-size: 13px;
  }

  @media all and (max-width: 480px) {
    width: 125px;
  }
`
const LocationIcon = styled.div`
  width: 20px;
  @media all and (max-width: 980px) {
    width: 15px;
  }
`
const Location = styled.img`
  display: block;
  width: 100%;
`
const Div = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: baseline;
  border-radius: 7px;
  @media all and (max-width: 1200px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    grid-gap: 30px;
    grid-template-columns: 1fr;
  }
  @media all and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`
const Heading = styled.h5`
  color: #0a0a0a;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: 'gordita_medium';
  text-align: left;
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
`
const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
  align-items: center;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
`
const LeftContainer = styled.div`
  width: 60%;
  margin-right: 50px;
  @media all and (max-width: 1400px) {
    width: 60%;
  }
  @media all and (max-width: 1200px) {
    width: 70%;
  }
  @media all and (max-width: 980px) {
    width: 70%;
    margin: 0 auto 50px auto;
  }
  @media all and (max-width: 768px) {
    width: 90%;
    margin: 0 auto 50px auto;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    margin: 0 auto 50px auto;
  }
`
const Top = styled.div`
  margin-bottom: 15px;
  width: 100%;
`
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`
const BottoomImageDiv = styled.div`
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
`
const RightContainer = styled.div`
  width: 45%;
  height: auto;
  @media all and (max-width: 980px) {
    width: 70%;
    margin: 0 auto;
  }
  @media all and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    margin: 0 auto;
  }
`
const Card = styled.a`
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.5s ease;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 9px 61px #0000000a;
  margin-bottom: 20px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  border: 1.5px solid #fff;
  &:hover {
    border: 1.5px solid #32c997;
    transition: all 0.5s ease;
  }
  @media all and (max-width: 1400px) {
    padding: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`
const Left = styled.div``
const Right = styled.div``
const LogoDiv = styled.div`
  width: 110px;
  margin-bottom: 30px;
  &.malabar {
    width: 40px;
    @media all and (max-width: 1200px) {
      width: 30px;
    }
  }
  &.stickon {
    width: 90px;
    @media all and (max-width: 1200px) {
      width: 70px;
    }
  }
  @media all and (max-width: 1200px) {
    width: 90px;
  }
`
const Logo = styled.img`
  display: block;
  width: 100%;
`
const IconContainer = styled.div`
  width: 30px;
`
const Icon = styled.img`
  display: block;
  width: 100%;
`
const Name = styled.h5`
  color: #0a0a0a;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: 'gordita_medium';
  @media all and (max-width: 1400px) {
    font-size: 14px;
  }
  @media all and (max-width: 980px) {
    font-size: 16px;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`
const Place = styled.p`
  color: #747474;
  font-size: 13px;
  @media all and (max-width: 1400px) {
    font-size: 12px;
  }
  @media all and (max-width: 980px) {
    font-size: 14px;
  }
`
const TopImage = styled.img`
  display: block;
  width: 100%;
`
const BottoomImage = styled.img`
  display: block;
  width: 100%;
`
const Mail = styled.a`
  color: #747474;
  font-size: 12px;
  @media all and (max-width: 1400px) {
    font-size: 11px;
  }
  @media all and (max-width: 980px) {
    font-size: 14px;
  }
`
